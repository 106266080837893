import $ from 'jquery-slim';

class Collapse
{
    constructor($element, $checkbox)
    {
        this.$element = $element;
        this.$checkbox = $checkbox;


        this.$element.on('show.bs.collapse', this.changing.bind(this));
        this.$element.on('hide.bs.collapse', this.changing.bind(this));


        this.$checkbox.change(this.change.bind(this));
        this.change();

    }

    change()
    {
        this.isShown = this.$checkbox.is(':checked');

        if (this.isChanging) return;

        this.$element.collapse(this.isShown ? 'show' : 'hide');

    }

    changing()
    {
        this.isChanging = true;
        setTimeout(this.changed.bind(this), 400);
    }

    changed()
    {
        this.isChanging = false;

        this.$element.collapse(this.$checkbox.is(':checked')? 'show' : 'hide');
    }

}

class CheckboxToggle
{
    constructor($element)
    {
        this.$element = $element;

        this.type = this.$element.attr('data-checkbox-toggle');
        this.target = this.$element.attr('data-target');

        this.$target = $(this.target);

        let tag = this.$element[0].tagName.toLocaleLowerCase();
        let type = this.$element.attr('type');

        switch(true)
        {
            case tag === 'label':
                let id = this.$element.attr('for');
                this.$checkbox = $('#' + id);
                break;

            case tag === 'input' && (type === 'checkbox' || type === 'radio'):
                this.$checkbox = this.$element;
                break;

        }

        switch(this.type)
        {
            case 'collapse':
                this.collapse = new Collapse(this.$target, this.$checkbox);


                break;

            default:
                console.log('type', this.type, 'not implemented in checkboxToggle yet');
        }
    }
}

class OptCheckboxToggle
{
    constructor()
    {
        $(document).ready(this.init.bind(this));
    }

    init()
    {
        let $elements = $('[data-checkbox-toggle]');


        this.toggles = [];

        for (let i = 0; i < $elements.length; i++) this.toggles.push(new CheckboxToggle($elements.eq(i)));
    }

}

const checkboxToggle = new OptCheckboxToggle();






